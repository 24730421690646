import React, { useState } from 'react';
import PageTransition from '../pageTransition';
import useDocumentScrollThrottled from '../useDocumentScrollThrottled';

import Burger from "../burger";
import Menu from "../menu";

import "./header.scss";

//import LogoHeader from "../../images/svg/ceres-icon.svg";
import LogoHeader60 from "../../images/svg/logo-header-60.svg";
import SearchIcon from "../../images/svg/search.svg";
import Search from "../search/search";

// HEADER + MENU + BURGER IMPLEMENTED BY THIS SOURCE:
// https://github.com/maximakymenko/react-burger-menu-article-app

const Header = ({ siteTitle }) => {

  const [open, setOpen] = useState(false);
  const menuId = "main-menu"

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const MINIMUM_SCROLL = 40;
  const TIMEOUT_DELAY = 250;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const hiddenStyle = shouldHideHeader ? 'hidden' : '';

  return (
    <>
      <header className={`header__wrapper ${hiddenStyle}`}>
        <PageTransition to="/" className="header__logo header__logo--width-auto" aria-label="Homepage" onClick={() => setOpen(false)}>
          <LogoHeader60 />
        </PageTransition>
        <div className="header__right">
          <Burger
            open={open}
            setOpen={setOpen}
            aria-controls={menuId}
          />
          <Menu
            open={open}
            setOpen={setOpen}
            id={menuId}
          >
              <button
                className={`SearchIcon`}
                onClick={()=>setIsSearchVisible(!isSearchVisible)}>
                <SearchIcon/>
              </button>
          </Menu>
        </div>
      </header>
      <Search
        isVisible={isSearchVisible}
      />
    </>
  );
}

Header.propTypes = {

};

Header.defaultProps = {

};

export default Header
