import React, {useEffect, useState} from 'react';
import lunr from 'lunr';
// import stemmerSupport from 'lunr-languages/lunr.stemmer.support.js';
// import it from 'lunr-languages/lunr.de.js';


import axios from 'axios';
// import {getParameterByName} from '../../helpers'
import "./search.scss";
import CONST from "../../config/constants";
import contents from "./search.yml";
import PageTransition from "../pageTransition";
import ButtonClose from '../buttonClose';

const Search = (props) => {

  const [query,setQuery] = useState(null);
  const [indexData,setIndexData] = useState(null);
  const [once,setOnce] = useState(false);
  const [results,setResults] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [isVisible,setIsVisible] = useState(false);

  const MIN_QUERY_LENGTH = 3;
  const FORCED_DELAY = 500;

  const closeSearch = () => {
    const body = window.document.body;
    setIsVisible(false);
    setResults(null);
    setQuery(null);
    body.classList.remove("search-is-open");
    window.document.removeEventListener("keydown", checkEsc);
  };
  const openSearch = () => {
    const body = window.document.body;
    setIsVisible(true);
    body.classList.add("search-is-open");
    window.document.addEventListener("keydown", checkEsc);
  };
  const checkEsc = (e) => {
    if(e.keyCode === 27) {
      closeSearch();
    }
  };

  useEffect(()=>{
    if(!props.isVisible) {
      closeSearch();
    }
    else {
      openSearch();
    }
  },[props.isVisible]);

  useEffect(()=>{
    if(!once) {
      setOnce(true);
      setIsLoading(true);
      axios.get(CONST.LUNR_INDEX)
        .then(function (response) {
          // console.log(response);
          setIndexData(response.data);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          if(window) {
            window.setTimeout(()=>{
              setIsLoading(false);
            },FORCED_DELAY);
          }
          else {
            setIsLoading(false);
          }
        });
    }
  },[once]);

  useEffect(()=>{
    if(query && query.length >= MIN_QUERY_LENGTH && indexData && indexData.LUNR_DATA) {

      setIsLoading(true);
      // console.log("QUERY", query);
      // console.log("DO SEARCH", indexData, indexData.LUNR_DATA, query);
      const idx = lunr.Index.load(indexData.LUNR_DATA);
      // idx.use(lunr.it);
      const results = idx.search(query);

      results.forEach((result,i)=>{
        results[i].preview = indexData.PREVIEW_LOOKUP[result.ref];
      });

      setResults(results);
      if(window) {
        window.setTimeout(()=>{
          setIsLoading(false);
        },FORCED_DELAY);
      }
      else {
        setIsLoading(false);
      }

      // console.log("======= RESULTS =======");
      // console.log(results);
      // console.log("=======================");
    }
  },[query,indexData]);


  const onResultClick = () => {
    setIsVisible(false);
    setResults(null);
    setQuery(null);
    closeSearch();
  };

  return (
      <div
        className={`Search ${isVisible ? `is-visible` : ``}`}
      >
          <ButtonClose
            onClick={closeSearch}
          />
        <div className="inner">
          <SearchField
            onChange={(e) => setQuery(e.target.value)}
            isLoading={isLoading}
            value={query || ""}
          />

          {!isLoading && query && query.length >= MIN_QUERY_LENGTH &&
          <>
            {results && results.length
              ?
              <SearchResults
                results={results}
                onClick={onResultClick}
                isLoading={isLoading}
              />
              :
              <NoSearchResults
                isLoading={isLoading}
              />
            }
          </>
          }
        </div>
      </div>
  )
};


const SearchField = ({onChange,isLoading,value}) => {
  return (
    <div
      className={`SearchField ${isLoading ? `is-loading` : ``}`}
    >
      <input type="text"
         placeholder={contents.placeholder}
         onChange={onChange}
         value={value}
      />
      { isLoading &&
        <div className={`loader`}/>
      }
    </div>
  );
};

const SearchResults = ({isLoading,results,onClick}) => {

  return (
    <div
      className={`SearchResults ${isLoading ? `is-loading` : ``}`}
    >
      <div className="inner">
        {results.map((result,i)=>{
          const link = `/${result.preview.l.replace("index.html","")}`;
          return (
            <div
              key={i}
              className={`result`}
            >
              <PageTransition
                to={link}
                onClick={onClick}
              >
                <h4>{result.preview.t}</h4>
                <p>{result.preview.p}</p>
              </PageTransition>
            </div>
          );
        })}
      </div>
    </div>
  )
};

const NoSearchResults = ({isLoading}) => {
  return (
    <div
      className={`NoSearchResults`}
    >
      <p>{contents.noResults}</p>
    </div>
  )
};

export default Search;
