const CONST = {};


CONST.ROUTES = {

  HOMEPAGE: '/',
  MORE_THAN_BEER: '/oltre-la-birra',
  CERES_WORLD: '/il-mondo-ceres',
  // CERES_QUALITY: '/la-qualita-ceres',
  // CONTESTS: '/concorsi',
  LEGGENDE_DI_STRADA: '/leggendedistrada',
  BEERS: '/birre',
  PRIVACY: '/privacy',
  COOKIES: '/privacy#cookies',
  CONTACTS: '/contatti',
  CAREERS: '/lavora-con-noi',
  REGULATIONS: '/regolamenti',
  CORPORATE_GOVERNANCE: '/pdf/corporate-governance-15062020.pdf',
  WHISTLEBLOWER: 'https://royalunibrew.whistleblowernetwork.net/frontpage'

};

CONST.JUICER_URL = 'https://www.juicer.io/api/feeds/ceres?per=200&page=1';
CONST.LUNR_INDEX = '/lunr-index.json';

CONST.PRODUCT_RANGES = [
  {
    id: 'strongale',
    slug: 'la-strong-ale',
    label: 'La strong ale',
    seoTitle: 'La strong ale',
    seoDescription: ''
  },
  {
    id: 'bottle',
    slug: 'in-bottiglia',
    label: 'In bottiglia',
    seoTitle: 'Birre in bottiglia',
    seoDescription: ''
  },
  {
    id: 'can',
    slug: 'in-lattina',
    label: 'In lattina',
    seoTitle: 'Birre in lattina',
    seoDescription: ''
  }
];

CONST.EVENT_TRACKING = {
  HPSLIDE_CTA_CLICK: 'homepage:cta:click'
};

CONST.MONTHS = [
  'gennaio',
  'febbraio',
  'marzo',
  'aprile',
  'maggio',
  'giugno',
  'luglio',
  'agosto',
  'settembre',
  'ottobre',
  'novembre',
  'dicembre'
];

CONST.COOKIES = {
  settings: 'ck_settings',
  technical: 'ck_technical',
  analytics: 'ck_analytics',
  advertising: 'ck_advertising'
}


export default CONST;
