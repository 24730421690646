import React, {useEffect} from 'react';
import { bool, func } from 'prop-types';

import "./burger.scss";

const Burger = ({ open, setOpen, ...props }) => {

  const isExpanded = open ? true : false;

  // TODO: check this code
  useEffect(()=>{
    if (open) {
      document.body.classList.add('no-overflow');
    } else {
      document.body.classList.remove('no-overflow');
    }
  })
  // end TODO

  return (
    <button className={`burger-menu ${open ? 'is-open' : ''}`} aria-label="Toggle menu" aria-expanded={isExpanded} open={open} onClick={() => setOpen(!open)} {...props}>
      <span />
      <span />
      <span />
    </button>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
