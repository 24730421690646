import CONST from "../config/constants";


export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const resolveLinkToRoute = (linkTo) => {
  if(CONST.ROUTES[linkTo]) {
    return CONST.ROUTES[linkTo];
  }
  return linkTo;
};

export const compareDisplayOrderDesc = (a, b) => {
  if(!a.hasOwnProperty('node') || !a.node.hasOwnProperty('displayOrder') || !b.hasOwnProperty('node') || !b.node.hasOwnProperty('displayOrder')) {
    return false;
  }

  if (a.node.displayOrder > b.node.displayOrder) {
    return -1;
  }
  if (a.node.displayOrder < b.node.displayOrder) {
    return 1;
  }
  return 0;
};

