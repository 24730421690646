import React, {useState,useEffect} from 'react';
import { useCookies } from 'react-cookie';

import "./ageCheck.scss";

import contents from "./ageCheck.yml";

const cookieName = `agecheck`;

const AgeCheck = () => {

  const [hide, setHide] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [cookies, setCookie] = useCookies([cookieName]);

  const [no18visbile, setNo18visible] = useState(false);

  const onAccept = () => {
    setCookie(cookieName, 'Y', {path: '/'});
    setAnimate(false);
    window.setTimeout(()=>{ setHide(true); }, 1500);
  };
  const onRefuse = () => {
    setNo18visible(true);
    setCookie(cookieName, 'N', {path: '/'});
  };

  useEffect(() => {
    // hide layer from search bots @todo: verify if it really works!!!
    if(/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)) {
      setHide(true);
    }
    else {
      if((!cookies[cookieName] || cookies[cookieName] !== 'Y')) {
        setHide(false);
        setAnimate(true);
      }
      else {
        setHide(true);
      }
    }
  },[setHide, setAnimate, cookies]);


  return (
    <>
      { !hide &&
        <div
          className={`age-check ${animate ? 'animate' : ''}`}
        >
          <div className={`age-check-content`}>

            {!no18visbile
              ?
                <>
                  <div><strong>{contents.title}</strong></div>
                  <div><small>{contents.subTitle}</small></div>
                  <div><strong>{contents.question}</strong></div>
                  <div><small>{contents.note}</small></div>
                  <div className="buttons">
                    <button onClick={onAccept}> {contents.yes} </button>
                    <button onClick={onRefuse}> {contents.no} </button>
                  </div>
                </>
              :
                <>
                  <div><strong>{contents.not18title}</strong></div>
                  <div>
                    <span className="no18text" dangerouslySetInnerHTML={{
                    __html: contents.not18text
                  }}/>
                  </div>
                </>
              }
          </div>
        </div>
      }
    </>
  );


};

AgeCheck.propTypes = {

};

export default AgeCheck;
