import React from 'react';
import PropTypes from "prop-types";

import "./button-close.scss";

import CloseSvg from "../../images/svg/close.svg";

const ButtonClose = (props) => {

  const {
    onClick
  } = props;


  return (
    <>
      <button
        className="button-close"
        aria-label="Chiudi"
        onClick={onClick}>
        <CloseSvg />
      </button>
    </>
  )
};

ButtonClose.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ButtonClose;
