import React, {useState} from "react";
import PropTypes from "prop-types";
import { CookiesProvider } from 'react-cookie';
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from "./header";
import MainBackground from "./backgroundImages/mainBackground";
import Footer from "./footer";
import AgeCheck from "./ageCheck";
import "../styles/index.scss";
// import CookieLayer from "./cookieLayer/cookieLayer";

const Layout = ({ children }) => {

  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const onCookieLinkClick = (e) => {
  //   e.preventDefault();
  //   setIsModalVisible(true);
  // };
  // const onCloseModal = () => {
  //   setIsModalVisible(false);
  // };

  return (
    <>

      <Header />

      {/*See gatsby-config.js at gatsby-plugin-transition-link implementation for info about this layout disposition*/}
      <MainBackground />
      <ParallaxProvider>
        <div id={`searchable-content`}>
          {children}
        </div>
      </ParallaxProvider>

      {/*<Footer onCookieLinkClick={onCookieLinkClick} />*/}
      <Footer/>

      <CookiesProvider>
        <AgeCheck/>
        {/*<CookieLayer
          showModal={isModalVisible}
          onCloseModal={onCloseModal}
        />*/}
      </CookiesProvider>

    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
