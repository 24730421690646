import React from 'react';
import PageTransition from '../pageTransition';
import { useStaticQuery, graphql } from "gatsby"
import { bool, func } from 'prop-types';

import "./menu.scss";

import contents from "./menu.yml"
import CONST from "../../config/constants";
import {resolveLinkToRoute, compareDisplayOrderDesc} from "../../helpers";

const Menu = ({ open, setOpen, ...props }) => {

  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;

  const data = useStaticQuery(graphql`
    query {
      cmsApi {
        getProductListing {
          edges {
            node {
              productCategory
              fullpath
              productName
              displayOrder
            }
          }
        }
      }
    }
  `);
  const edges = data.cmsApi.getProductListing.edges;
  const beers = {};
  const ranges = CONST.PRODUCT_RANGES;

  edges.sort(compareDisplayOrderDesc);
  edges.forEach(({node})=>{
    if(!beers[node.productCategory]) {
      beers[node.productCategory] = [];
    }
    beers[node.productCategory].push(node);
  });

  return (
    <nav
      className={`main-nav__wrapper ${open ? 'is-open' : ''}`}
      aria-hidden={!isHidden}
      itemScope
      itemType="http://www.schema.org/SiteNavigationElement"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className="main-nav__inner">
        <div className="main-nav__first-level">
          <span>
            {contents.beerMenu.label}
          </span>
          <ul className="main-nav__second-level">

            {ranges && ranges.length && ranges.map((range, i) => {
              const rangeBeers = beers[range.id];
              return (
                  <li itemProp="name" key={i}>
                    <MenuItem
                        link={`${CONST.ROUTES.BEERS}/${range.slug}`}
                        tabIndex={tabIndex}
                        onClick={() => setOpen(false)}
                    >{range.label}</MenuItem>

                    {rangeBeers && rangeBeers.length > 1 &&
                        <ul className="main-nav__third-level">
                          {rangeBeers.map((beer, ii) => {

                            if (!beer.slug) {
                              beer.slug = (beer.fullpath.substring(beer.fullpath.lastIndexOf('/') + 1));
                            }

                            return (
                                <li itemProp="name" key={ii}>
                                  <MenuItem
                                      link={`${CONST.ROUTES.BEERS}/${range.slug}/${beer.slug}`}
                                      tabIndex={tabIndex}
                                      onClick={() => setOpen(false)}
                                  >{beer.productName}</MenuItem>
                                </li>
                            );
                          })}
                        </ul>
                    }

                  </li>
              );
            })}

          </ul>
        </div>
        {/*<div className="main-nav__first-level">
          <span>
            <a href={contents.eShop.link} target={"_blank"} rel={"noopener noreferrer"}>{contents.eShop.label}</a>
          </span>
        </div>*/}
        <div className="main-nav__first-level">
          <span>
            <a href={contents.vinciUnCero.link} target={"_blank"} rel={"noopener"}>{contents.vinciUnCero.label}</a>
          </span>
        </div>
        <div className="main-nav__first-level">
          <span>
            <MenuItem
                link={resolveLinkToRoute(contents.leggendeDiStrada.link)}
                tabIndex={tabIndex}
                onClick={() => setOpen(false)}
            >
              {contents.leggendeDiStrada.label}
            </MenuItem>
          </span>
        </div>
        <div className="main-nav__first-level">
          <span>
            <MenuItem
                link={resolveLinkToRoute(contents.mondoCeres.link)}
                tabIndex={tabIndex}
                onClick={() => setOpen(false)}
            >
              {contents.mondoCeres.label}
            </MenuItem>
          </span>
        </div>
        {/*<div className="main-nav__first-level">
          <span>
            {contents.moreThanBeerMenu.label}
          </span>
          <ul className="main-nav__second-level">
            {contents.moreThanBeerMenu.items && contents.moreThanBeerMenu.items.length && contents.moreThanBeerMenu.items.map((item, i)=>{
              return (
                <li itemProp="name" key={i}>
                  <MenuItem
                    link={resolveLinkToRoute(item.link)}
                    tabIndex={tabIndex}
                    onClick={() => setOpen(false)}
                  >{item.label}</MenuItem>
                </li>
              );
            })}
          </ul>
        </div>*/}
        {props.children}
      </div>
    </nav>
  )
};


const MenuItem = (props) => {

  const {
    link,
    onClick,
    tabIndex,
    children
  } = props;

  return (
      <PageTransition
          to={link}
          tabIndex={tabIndex}
          className=""
          onClick={onClick}
          itemProp="url"
      >
        <span>{children}</span>
      </PageTransition>
  );
};


Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Menu;
