// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-beer-details-template-beer-details-template-js": () => import("./../../../src/components/beerDetailsTemplate/beerDetailsTemplate.js" /* webpackChunkName: "component---src-components-beer-details-template-beer-details-template-js" */),
  "component---src-components-beers-range-template-beers-range-template-js": () => import("./../../../src/components/beersRangeTemplate/beersRangeTemplate.js" /* webpackChunkName: "component---src-components-beers-range-template-beers-range-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-campioni-da-bar-bar-ceres-js": () => import("./../../../src/pages/campioni-da-bar/bar-ceres.js" /* webpackChunkName: "component---src-pages-campioni-da-bar-bar-ceres-js" */),
  "component---src-pages-campioni-da-bar-index-js": () => import("./../../../src/pages/campioni-da-bar/index.js" /* webpackChunkName: "component---src-pages-campioni-da-bar-index-js" */),
  "component---src-pages-concorsi-js": () => import("./../../../src/pages/concorsi.js" /* webpackChunkName: "component---src-pages-concorsi-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-il-mondo-ceres-js": () => import("./../../../src/pages/il-mondo-ceres.js" /* webpackChunkName: "component---src-pages-il-mondo-ceres-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../../../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-leggendedistrada-js": () => import("./../../../src/pages/leggendedistrada.js" /* webpackChunkName: "component---src-pages-leggendedistrada-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-regolamenti-js": () => import("./../../../src/pages/regolamenti.js" /* webpackChunkName: "component---src-pages-regolamenti-js" */)
}

