import React from 'react';
import TransitionLink from "gatsby-plugin-transition-link";

const PageTransition = ({ to, onClick, className, children }) => (
  <TransitionLink
    activeClassName="active"
    className={className}
    onClick={onClick}
    to={to}
    exit={{
      delay: 0.15,
      length: 0.3
    }}
    entry={{
      delay: 0.15,
      length: 0.3
    }}
    >
    {children}
  </TransitionLink>
)

export default PageTransition;
