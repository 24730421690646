import React from 'react';

import "./footer.scss";
import PageTransition from "../pageTransition";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import FacebookIcon from "../../images/svg/facebook.svg";
import TwitterIcon from "../../images/svg/twitter.svg";
import InstagramIcon from "../../images/svg/instagram.svg";
import YouTubeIcon from "../../images/svg/youtube.svg";
import CeresLogo from "../../images/svg/ceres-logo.svg";
import {resolveLinkToRoute} from "../../helpers";

import contents from "./footer.yml"


const ServicePagesMenu = ({servicePages,onCookieLinkClick}) => {



  return (
    <nav
      className={`service-pages`}
    >
      <ul>
        { servicePages.map((item, i) => {

          const link = item.link && resolveLinkToRoute(item.link);
          const isPdf = link && link.includes("pdf");
          const isExternal = item.isExternal;

          return (
            <li key={i}>

              { isPdf || isExternal
                ?
                  <a target="_blank" rel="noopener noreferrer" href={link} aria-label={item.label}>{item.label}</a>
                :
                  item.cookieLink
                  ?
                    <a href="#cookie-layer" onClick={onCookieLinkClick} aria-label={item.label}>{item.label}</a>
                  :
                    <PageTransition to={link} aria-label={item.label}>{item.label}</PageTransition>
              }
            </li>
          );
        })
        }
      </ul>
    </nav>
  )
};

const SocialNetworksMenu = ({socialNetworks}) => {
  const icons = {
    'facebook': <FacebookIcon/>,
    'twitter': <TwitterIcon/>,
    'instagram': <InstagramIcon/>,
    'youtube': <YouTubeIcon/>
  };
  return (
    <nav
      className={`follow-us`}
    >
      <ul>
        { socialNetworks.map((item, i) => {
          return (
            <li key={i}>
              <OutboundLink
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={item.label}
                // eventLabel={`Footer ${item.label}`}
              >
                {icons[item.icon]}
              </OutboundLink>
            </li>
          );
        })
        }
      </ul>
    </nav>
  );
};


const Footer = ({onCookieLinkClick}) => {

  const {
    servicePages,
    socialNetworks
  } = contents;


  return (
    <footer className="footer__wrapper">
      <div className="footer-row">
        <div className="col">
          <ServicePagesMenu
            servicePages={servicePages}
            onCookieLinkClick={onCookieLinkClick}
          />
        </div>
        <div className="col">
          <span>Follow us:</span>
          <SocialNetworksMenu
            socialNetworks={socialNetworks}
          />
        </div>
      </div>
      <div className="footer-row footer-row--a-end">
        <div className="col">
          <address>
            SEDE LEGALE - Viale Sarca, 226 - 20126 – Milano <br/>
            P.IVA/C.F. 00261810105
          </address>
        </div>
        <div className="col">
          <div className="ceres-logo">
            <CeresLogo/>
          </div>
        </div>
      </div>
    </footer>
  );

};

Footer.propTypes = {

};

Footer.defaultProps = {

};

export default Footer;
